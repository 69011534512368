import GlobalParam from "./AppGlobalParam";

const PolicyAuth = () => {
    const auth = localStorage.getItem('auth');
    const access_token = localStorage.getItem('access_token');

    if (auth === null || access_token === null) {
        exitAccount(false);
        return false;
    } else
        return true;
}

export default PolicyAuth;

export const goAccount = (data) => {
    if (data['access_token'] !== undefined) {
        localStorage.setItem('auth', true);
        localStorage.setItem('access_token', data['access_token']);
        window.location.href = "/account";
    }
}

export const exitAccount = (location = true) => {
        localStorage.clear();
        if(location)
            window.location.href = GlobalParam.domain;
}