import React from 'react';
import {Mosaic} from "react-loading-indicators";

const LoadingIcon = () => {
    return (
        <div className="text-center">
            <Mosaic size="small" text="Ждите..." textColor="#000000" color="#343a40" />
        </div>
    );
};

export default LoadingIcon;
