import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainPage from "./Pages/OpenPart/MainPage";
import PolicyAuth from "./Utility/Policy";
import AccountMainPage from "./Pages/ClosePart/AccountMainPage";
import AccountGamesPage from "./Pages/ClosePart/AccountGamesPage";
import AccountFinancePage from "./Pages/ClosePart/AccountFinancePage";
import AccountStatisticPage from "./Pages/ClosePart/AccountStatisticPage";
import AccountSettingsPage from "./Pages/ClosePart/AccountSettingsPage";
import apiRequest from "./Utility/ApiRequest";
import AccountKaffDBPage from "./Pages/ClosePart/AccountKaffDBPage";
import AccountValueStartegy from "./Pages/ClosePart/AccountValueStartegy";
import '../src/js/main'
import './css/style.css'

const App = () => {

    const [mainData, setMainData] = useState(false);

    useEffect(() => {
        apiRequest("getMainData?").then((data) => {
           if(data['success']){
               setMainData(data);
           }
        });
    }, []);

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    {PolicyAuth() ? <Route path="*" element={<AccountMainPage mainData={mainData} />} /> : <Route path="*" element={<MainPage />} /> }

                    {PolicyAuth() ? <Route exact path="/value" element={<AccountValueStartegy mainData={mainData} />} /> : false }
                    {/*{PolicyAuth() ? <Route exact path="/games" element={<AccountGamesPage mainData={mainData} />} /> : false }*/}
                    {/*{PolicyAuth() ? <Route exact path="/kaffdb" element={<AccountKaffDBPage mainData={mainData} />} /> : false }*/}
                    {/*{PolicyAuth() ? <Route exact path="/finance" element={<AccountFinancePage mainData={mainData} />} /> : false }*/}
                    {/*{PolicyAuth() ? <Route exact path="/statistic" element={<AccountStatisticPage mainData={mainData} />} /> : false }*/}
                    {/*{PolicyAuth() ? <Route exact path="/settings" element={<AccountSettingsPage mainData={mainData} />} /> : false }*/}
                </Routes>
            </BrowserRouter>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default App;
