import React from 'react';
import ShablonAccount from "../../Components/ShablonAccount/ShablonAccount";
import logo from '../../Images/placat.png';

const AccountMainPage = (props) => {
    const citati = [];
    citati.push({text: "Если вы можете мечтать об этом, вы можете это сделать.", aut: "Уолт Дисней"});
    citati.push({text: "Успех - это идти от неудачи к неудаче, не теряя энтузиазма.", aut: "Уинстон Черчилль"});
    citati.push({text: "Не считай дни, извлекай из них пользу.", aut: "Мухаммед Али"});
    citati.push({text: "Не ждите. Время никогда не будет подходящим.", aut: "Наполеон Хилл"});
    citati.push({text: "Неисследованная жизнь не стоит того, чтобы ее жить.", aut: "Сократ"});
    citati.push({text: "Я не потерпел неудачу. Я просто нашел 10 000 способов, которые не работают.", aut: "Томас Эдисон"});
    citati.push({text: "Мотивация - это то, что заставляет вас начать. Привычка - это то, что заставляет вас продолжать.", aut: "Джим Рюн"});
    citati.push({text: "Вы должны выучить правила игры. А затем вы должны играть лучше, чем кто-либо другой.", aut: "Альберт Эйнштейн"});
    citati.push({text: "Если вы потратите свою жизнь на то, чтобы быть лучшим во всем, вы никогда не станете великим ни в чем.", aut: "Том Рат"});
    citati.push({text: "Сначала они не замечают тебя, затем смеются над тобой, потом борются с тобой, а потом ты побеждаешь.", aut: "Махатма Ганди"});
    citati.push({text: "Мечтатели - это спасители мира.", aut: "Джеймс Аллен"});
    citati.push({text: "Лучшая месть - это огромный успех.", aut: "Фрэнк Синатра"});
    citati.push({text: "Измени свои мысли и ты изменишь мир.", aut: "Норман Винсент Пил"});
    citati.push({text: "Упорный труд побеждает талант, когда талант не трудится.", aut: "Неизвестный"});
    citati.push({text: "Мужество - первое из человеческих качеств, потому что это качество, которое гарантирует все остальные.", aut: "Уинстон Черчилль"});
    citati.push({text: "Победа - это еще не все, главное это постоянное желание побеждать.", aut: "Винс Ломбарди"});
    citati.push({text: "Чтобы справиться с собой, используйте голову; чтобы справиться с другими, используйте свое сердце.", aut: "Элеонора Рузвельт"});
    citati.push({text: "Неудача никогда не одолеет меня, если моя решимость добиться успеха достаточно сильна.", aut: "Ог Мандино"});
    citati.push({text: "Я всегда выберу ленивого человека для выполнения сложной работы, потому что он найдет легкий путь ее выполнения.", aut: "Билл Гейтс"});
    citati.push({text: "Жизнь на 10% состоит из того, что с вами происходит, и на 90% из того, как вы на это реагируете.", aut: "Чарльз Р. Свиндолл"});
    const indexCitata = Math.floor(Math.random() * ((citati.length-1) - 0 + 1)) + 0;

    return (
        <ShablonAccount razdel_id="0" mainData={props.mainData} title="Главная страница">
            <img className="bi-card-image w-100" src={logo} alt="" />
            <h2 className="pb-2 p-5">{citati[indexCitata]['text']}</h2>
            <hr />
            <p className="text-right pr-5">{citati[indexCitata]['aut']}</p>
        </ShablonAccount>
    );
};

export default AccountMainPage;
