import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import logo from "../../Images/placat.png";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import $ from 'jquery';

const ShablonAccount = (props) => {

    // const [disabledBtnStartAnalise, setDisabledBtnStartAnalis] = useState(false);

    const dep = Math.random() * 10000;

    useEffect(() => {
        if (props.razdel_id)
            $("#razdel_" + props.razdel_id).addClass("bg-warning");
    }, [dep, props.razdel_id]);

    // const getInfoNextAnaliz = () => {
    //     // setDisabledBtnStartAnalis(true);
    //     props.mainData['settings']['start_analize'] = "1";
    //     apiRequest("InfoAnaliz?", "").then((data) => {
    //        if (data['success']){
    //            apiRequest("StartAnalize?", "").then((data2) => {
    //                if (data2['success'])
    //                    toast.success(data2['success']);
    //                else
    //                    toast.error(data2['error']);
    //            });
    //            const Msg = ({ closeToast }) => (
    //                <div>
    //                    {data['count_game']}
    //                    <br />{data['count_hours']}
    //                    <br />{data['count_minute']}
    //                </div>
    //            )
    //            toast.info(<Msg />);
    //        } else {
    //            toast.error(data['error']);
    //        }
    //     });
    // }

    return (
        <main className="container min-vh-100" style={{minWidth: "85%"}}>
            <div className="min-vh-100">
                    {
                        props.mainData
                            ? <div className="mx-auto w-100 shadow-lg">
                                <div className="row" style={{minHeight: 700, maxHeight: 700,}}>
                                    <div className="col-md-2 bg-dark p-0">
                                        <div className="card sticky-top bg-dark">
                                            <img className="bi-card-image w-100" src={logo} alt="" />
                                            <div className="h-100">
                                                <ul className="nav flex-column" style={{fontSize: 18}}>
                                                    <li id="razdel_0" className="nav-item">
                                                        <Link className="nav-link text-white" to="/account">
                                                            <i className="bi bi-house-fill"> </i>Главная страница</Link>
                                                    </li>
                                                    <li id="razdel_0" className="nav-item">
                                                        <Link className="nav-link text-white" to="/value">
                                                            <i className="bi bi-shuffle"></i> Рабочий стол</Link>
                                                    </li>
                                                    {/*<li id="razdel_1" className="nav-item pr-3 d-flex justify-content-between align-items-center">*/}
                                                    {/*    <Link className="nav-link text-white" to="/games">*/}
                                                    {/*        <i className="bi bi-card-checklist"> </i>Игры на сегодня*/}
                                                    {/*    </Link>*/}
                                                    {/*    {*/}
                                                    {/*        props.mainData['count_game_today'] > 0*/}
                                                    {/*            ? <span className="badge badge-success ml-2">{props.mainData['count_game_today']}</span>*/}
                                                    {/*            : false*/}
                                                    {/*    }*/}
                                                    {/*</li>*/}
                                                    {/*<li id="razdel_3" className="nav-item">*/}
                                                    {/*    <Link className="nav-link text-white" to="/kaffdb">*/}
                                                    {/*        <i className="bi bi-database-fill-check"> </i>База коэффициентов</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li id="razdel_2" className="nav-item">*/}
                                                    {/*    <Link className="nav-link text-white" to="/finance">*/}
                                                    {/*        <i className="bi bi-cash-stack"> </i>Финансы</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li id="razdel_3" className="nav-item">*/}
                                                    {/*    <Link className="nav-link text-white" to="/statistic">*/}
                                                    {/*        <i className="bi bi-graph-up"> </i>Статистика</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li id="razdel_4" className="nav-item">*/}
                                                    {/*    <Link className="nav-link text-white" to="/settings">*/}
                                                    {/*        <i className="bi bi-gear-fill"> </i>Настройки</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li className="nav-item">*/}
                                                    {/*    <Link className="nav-link text-white" onClick={(event) => exitAccount()}>*/}
                                                    {/*        <i className="bi bi-box-arrow-right"> </i>Выйти</Link>*/}
                                                    {/*</li>*/}
                                                </ul>
                                                {/*<div className="p-2">*/}
                                                {/*    <div style={{background: "#000000"}} className="shadow-lg p-3 rounded text-white border-top border-success">*/}
                                                {/*        <div className="d-flex justify-content-between align-items-center mb-1">*/}
                                                {/*            Игр завершено: <span className="badge badge-primary p-2 ml-2"><b>{props.mainData['count_end_game_all']}</b></span>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="d-flex justify-content-between align-items-center mb-1">*/}
                                                {/*            Прошло: <span className="badge badge-primary p-2 ml-2"><b>{props.mainData['count_end_game_prohod_success_all']}</b></span>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="d-flex justify-content-between align-items-center mb-1">*/}
                                                {/*            Общий процент: <span className="badge badge-primary p-2 ml-2"><b>{props.mainData['procent_today_prohod_all']}%</b></span>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="d-flex justify-content-between align-items-center mb-1">*/}
                                                {/*            Проход. хозяев: <span className="badge badge-primary p-2 ml-2"><b>{props.mainData['count_end_game_prohod_success_all_home']}%</b></span>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="d-flex justify-content-between align-items-center mb-1">*/}
                                                {/*            Проход. гостей: <span className="badge badge-primary p-2 ml-2"><b>{props.mainData['count_end_game_prohod_success_all_away']}%</b></span>*/}
                                                {/*        </div>*/}
                                                {/*        <hr className="border-white"/>*/}
                                                {/*        <div className="d-flex justify-content-between align-items-center mb-1">*/}
                                                {/*            Тотал. 0.5Б гости: <span className="badge badge-primary p-2 ml-2"><b>{props.mainData['procent_prohod_game_away_not_null_goal']}%</b></span>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="d-flex justify-content-between align-items-center mb-1">*/}
                                                {/*            <span className="text-success">Прошло</span> {props.mainData['count_end_game_away_not_null_goal']} <span>|</span> <span className="text-danger">Не прошло</span> {props.mainData['count_end_game_away_null_goal']}*/}
                                                {/*        </div>*/}
                                                {/*        <hr className="border-white"/>*/}
                                                {/*        <div className="d-flex justify-content-between align-items-center mb-1">*/}
                                                {/*            Обе забьют: <span className="badge badge-primary p-2 ml-2"><b>{props.mainData['procent_obe_zabut']}%</b></span>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="d-flex justify-content-between align-items-center mb-1">*/}
                                                {/*            ТБ 1.5: <span className="badge badge-primary p-2 ml-2"><b>{props.mainData['procent_bolshe_1_5']}%</b></span>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="d-flex justify-content-between align-items-center mb-1">*/}
                                                {/*            ТБ 2.5: <span className="badge badge-primary p-2 ml-2"><b>{props.mainData['procent_bolshe_2_5']}%</b></span>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="d-flex justify-content-between align-items-center mb-1">*/}
                                                {/*            ТБ 3.5: <span className="badge badge-primary p-2 ml-2"><b>{props.mainData['procent_bolshe_3_5']}%</b></span>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="d-flex justify-content-between align-items-center mb-1">*/}
                                                {/*            ТБ 4.5: <span className="badge badge-primary p-2 ml-2"><b>{props.mainData['procent_bolshe_4_5']}%</b></span>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*<div className="p-2">*/}
                                                {/*    <div style={{background: "#000000"}} className="shadow-lg p-3 rounded text-white border-top border-warning">*/}
                                                {/*        <div className="d-flex justify-content-between align-items-center mb-1">*/}
                                                {/*            Подписчики: <span className="badge badge-primary p-2 ml-2"><b>{props.mainData['subscribers']}</b></span>*/}
                                                {/*        </div>*/}
                                                {/*        <a href="https://web.telegram.org/k/#@Betust_SM" className="text-success" target="Blank">*/}
                                                {/*            <i className="bi bi-telegram text-white"> </i>Betust_SM*/}
                                                {/*        </a>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*<div className="p-2">*/}
                                                {/*    <a href="https://winline.ru" className="btn btn-success w-100" target="Blank">*/}
                                                {/*        <img src={winline} width="25" alt="" /> <b>Перейти в Winline</b>*/}
                                                {/*    </a>*/}
                                                {/*</div>*/}
                                                {/*<div className="p-2">*/}
                                                {/*    <a href="https://www.flashscorekz.com/?rd=flashscore.ru" className="btn w-100 text-white" target="Blank" style={{background: "#000000"}}>*/}
                                                {/*        <img src={flashscore} width="25" alt="" /> <b>Открыть Flashscore</b>*/}
                                                {/*    </a>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card sticky-top col-md-10 bg-white" style={{overflowX: "hidden"}}>
                                        <h4 className="pt-3">{props.title}</h4>
                                        <hr />
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                            : <LoadingIcon />
                    }
            </div>
        </main>
    );
};

export default ShablonAccount;
