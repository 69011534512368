import React, {useEffect, useState} from 'react';
import ShablonAccount from "../../Components/ShablonAccount/ShablonAccount";
import apiRequest from "../../Utility/ApiRequest";
import LoadingIcon from "../../Components/LoadingIcon/LoadingIcon";
import Modal2 from "../../Components/Modal/Modal2";
import $ from 'jquery';
import {toast} from "react-toastify";

const AccountValueStartegy = (props) => {

    const [games, setGames] = useState(false);
    const [currentGameData, setCurrentGameData] = useState(false);
    const [date, setDate] = useState("");

    const [notGameToday, setNotGameToday] = useState(false);

    let last_odd_home = 0
    let last_odd_draw = 0
    let last_odd_away = 0

    useEffect(() => {
        loadGame();

        // Устанавливаем интервал, чтобы функция выполнялась каждые 3 минуты
        const intervalId = setInterval(() => {
            loadGame();
        }, 3 * 60 * 1000); // 3 минуты в миллисекундах

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);

    }, []);

    const loadGame = (dateD = date) => {
        apiRequest("getGame?", "date=" + dateD).then((data) => {
            if (data['success']){
                setGames(data['games']);
            } else {
                setNotGameToday(true);
            }
        });
    }

    const deleteGame = (id) => {
        apiRequest("deleteGame?", "id=" + id + "&date=" + date).then((data) => {
            if (data['success']) {

                setGames(data['games']);
                toast.success(data['success']);
            }

            if (data['error'])
                toast.error(data['error']);
        });
    }

    const setStatusPary = (id, status) => {
        apiRequest("setStatusPary?", "id=" + id + "&status=" + status + "&date=" + date).then((data) => {
            if (data['success']) {
                setGames(data['games']);
                toast.success(data['success']);
            }

            if (data['error'])
                toast.error(data['error']);
        });
    }

    return (
    <ShablonAccount razdel_id="2" mainData={props.mainData} title="Наш офис">
        {
            (games)
                ? <div className="table-responsive">
                    <form action="" method="GET">
                        <div className="input-group">
                            <input type="date" placeholder="Выберите дату" className="form-control" onChange={(event) => {
                                setDate(event.target.value);
                                loadGame(event.target.value);
                            }} />
                            <button onClick={(event) => {
                                event.preventDefault();
                                loadGame("");
                                setDate("");
                            }} className="btn btn-outline-danger">очистить</button>
                        </div>
                    </form>
                    <table className="table mt-2">
                        <thead>
                        <tr>
                            <th className="text-center" scope="col">#</th>
                            <th className="text-center" scope="col">Время</th>
                            <th className="text-center" scope="col">Лига</th>
                            <th className="text-center" scope="col">Игра</th>
                            <th className="text-center" scope="col">П1</th>
                            <th className="text-center" scope="col">Ничья</th>
                            <th className="text-center" scope="col">П2</th>
                            <th className="text-center" scope="col">Разница в очках</th>
                            <th className="text-center" scope="col">Стратегии</th>
                            <th className="text-center" scope="col">Результат</th>
                            <th className="text-center" scope="col" style={{minWidth: 200}}>Инструменты</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            Object.keys(games).map((index, i) => {

                                return <tr className={(games[index]['status_pary'] === 1) ? "alert-success" : (games[index]['status_pary'] === -1) ? "alert-danger" : ""}>
                                    <td className="text-center align-middle"><b className={(games[index]['result'] === "-") ? "bg-success text-white p-1" : (games[index]['life'] === 1) ? "bg-warning p-1" : " bg-dark text-white p-1"}>{i+1}</b></td>
                                    <td className="text-center align-middle"><b>{games[index]['event__stage'].split(" ").at(-1)}</b></td>
                                    <td  className="align-middle">{games[index]['liga']}</td>
                                    <td className="text-dark align-middle"><b><a className="text-dark" href={games[index]['url']} target="_blank" rel="noreferrer">{games[index]['home_team_name']}({games[index]['home_score']}) - ({games[index]['away_score']}) {games[index]['away_team_name']}</a></b></td>
                                    <td className="text-center align-middle"><b>{games[index]['odd_home'].split("|").at(-1)}</b></td>
                                    <td className="text-center align-middle"><b>{games[index]['odd_draw'].split("|").at(-1)}</b></td>
                                    <td className="text-center align-middle"><b>{games[index]['odd_away'].split("|").at(-1)}</b></td>
                                    <td className="text-center align-middle">
                                        <b className={(games[index]['game_isset'] === 1) ? "bg-success p-2 text-white" : ""}>
                                        {
                                            (parseInt(games[index]['home_score']) > parseInt(games[index]['away_score']))
                                                ? parseInt(games[index]['home_score']) - parseInt(games[index]['away_score'])
                                                : parseInt(games[index]['away_score']) - parseInt(games[index]['home_score'])
                                        }
                                        </b>
                                    </td>
                                    <td className="align-middle"><h5>{
                                        (games[index]['gameValueKaff'] === 1)
                                            ? <i className="bi bi-arrow-up-square-fill"></i>
                                            : ""
                                    }{
                                        (games[index]['gameMathOtklon'] === 1)
                                            ? <i className="bi bi-graph-down ml-2"></i>
                                            : ""
                                    }</h5></td>
                                    <td className="align-middle"><b>{games[index]['result']} {games[index]['time_life']}</b></td>
                                    <td className="align-middle"><h5>
                                        <i className="bi bi-sort-numeric-up-alt" onClick={(event) => {
                                            setCurrentGameData(games[index]);
                                            last_odd_away = 0;
                                            last_odd_home = 0;
                                            last_odd_draw = 0;
                                        }} modal-open="true" modal-id="modal_kaff_history" style={{cursor: "pointer"}}></i>
                                        <i modal-open="true" modal-id="modal_table_HT" onClick={(event) => {
                                            $("#table_ft_div").html(games[index]['ht_ft_table']);
                                        }} className="ml-2 bi bi-table" style={{cursor: "pointer"}}></i>
                                        <i className="ml-2 bi bi-trash3-fill text-danger" style={{cursor: "pointer"}} onClick={(event) => deleteGame(games[index]['id'])}></i>
                                        <i className="ml-2 bi bi-bookmark-check-fill text-success" style={{cursor: "pointer"}} onClick={(event) => setStatusPary(games[index]['id'], 1)}></i>
                                        <i className="ml-2 bi bi-bookmark-check-fill text-danger" style={{cursor: "pointer"}} onClick={(event) => setStatusPary(games[index]['id'], -1)}></i>
                                    </h5></td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
                : (notGameToday)
                    ? <div className="alert alert-danger">Извините, на сегодня игр нет.</div>
                    : <LoadingIcon />
        }

        <Modal2 size="modal-medium2" title="Таблица HT/FT" id="modal_table_HT">
            <div id="table_ft_div">

            </div>
        </Modal2>

        <Modal2 size="modal-narrow2" title="История коэффициентов" id="modal_kaff_history">
            {
                (currentGameData)
                    ? <div className="row">
                        <div className="col-md-4">
                            <p className="text-center p-2">П1</p>
                            <hr />
                            <ol className="list-group list-group-numbered">
                            {
                                currentGameData['odd_home'].split("|").map((elem) => {
                                    if (last_odd_home === 0 && elem !== "") {
                                        last_odd_home = parseFloat(elem)
                                        return <li className="list-group-item">{elem}</li>
                                    } else if (parseFloat(elem) > last_odd_home){
                                        last_odd_home = parseFloat(elem)
                                        return <li className="list-group-item"><i className="bi bi-arrow-up-circle-fill text-success"></i> {elem}</li>
                                    } else if (parseFloat(elem) < last_odd_home){
                                        last_odd_home = parseFloat(elem)
                                        return <li className="list-group-item"><i className="bi bi-arrow-down-circle-fill text-danger"></i> {elem}</li>
                                    }
                                    if (elem !== "")
                                        return <li className="list-group-item">{elem}</li>

                                    return ""
                                })
                            }
                            </ol>
                        </div>
                        <div className="col-md-4">
                            <p className="text-center p-2">Ничья</p>
                            <hr />
                            <ol className="list-group list-group-numbered">
                                {
                                    currentGameData['odd_draw'].split("|").map((elem) => {
                                        if (last_odd_draw === 0 && elem !== "") {
                                            last_odd_draw = parseFloat(elem)
                                            return <li className="list-group-item">{elem}</li>
                                        } else if (parseFloat(elem) > last_odd_draw){
                                            last_odd_draw = parseFloat(elem)
                                            return <li className="list-group-item"><i className="bi bi-arrow-up-circle-fill text-success"></i> {elem}</li>
                                        } else if (parseFloat(elem) < last_odd_draw){
                                            last_odd_draw = parseFloat(elem)
                                            return <li className="list-group-item"><i className="bi bi-arrow-down-circle-fill text-danger"></i> {elem}</li>
                                        }
                                        if (elem !== "")
                                            return <li className="list-group-item">{elem}</li>

                                        return ""
                                    })
                                }
                            </ol>
                        </div>
                        <div className="col-md-4">
                            <p className="text-center p-2">П2</p>
                            <hr />
                            <ol className="list-group list-group-numbered">
                                {
                                    currentGameData['odd_away'].split("|").map((elem) => {
                                        if (last_odd_away === 0 && elem !== "") {
                                            last_odd_away = parseFloat(elem)
                                            return <li className="list-group-item">{elem}</li>
                                        } else if (parseFloat(elem) > last_odd_away){
                                            last_odd_away = parseFloat(elem)
                                            return <li className="list-group-item"><i className="bi bi-arrow-up-circle-fill text-success"></i> {elem}</li>
                                        } else if (parseFloat(elem) < last_odd_away){
                                            last_odd_away = parseFloat(elem)
                                            return <li className="list-group-item"><i className="bi bi-arrow-down-circle-fill text-danger"></i> {elem}</li>
                                        }
                                        if (elem !== "")
                                            return <li className="list-group-item">{elem}</li>

                                        return ""
                                    })
                                }
                            </ol>
                        </div>
                    </div>
                    : false
            }
        </Modal2>

    </ShablonAccount>
    );
};

export default AccountValueStartegy;
