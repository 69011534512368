import React from 'react';
import logo from '../../Images/logo.png';
import SendForm from "../../Utility/SendForm";
import {toast} from "react-toastify";
import {goAccount} from "../../Utility/Policy";

const MainPage = () => {

    return (
        <main className="container min-vh-100 d-flex justify-content-center align-items-center">
            <div className="mx-auto" style={{maxWidth: 300}} >
                <form action="enter" method="GET" id="EnterForm" noValidate onSubmit={(event) => SendForm(event, (data) => {
                    if (data['success']){
                        toast.success(data['success']);
                        goAccount(data['data']);
                    }
                })}>
                    <img className="mb-4" src={logo} alt="" width={300} />
                    <h1 className="h3 mb-3 fw-normal text-center">Войти в аккаунт</h1>

                    <div className="form-floating pt-2">
                        <input type="text" required name="login" className="form-control" placeholder="Введите логин" />
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                    </div>
                    <div className="form-floating pt-2">
                        <input type="password" required name="password" className="form-control" placeholder="Введите пароль" />
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                    </div>
                    <div className="alert alert-danger d-none mt-2">

                    </div>
                    <div className=" pt-2">
                        <input type="submit" className="btn btn-primary w-100" value="Войти" />
                    </div>

                    <p className="mt-2 mb-3 text-body-secondary text-center">© 2020-2023</p>
                </form>
            </div>
        </main>
    );
};

export default MainPage;
